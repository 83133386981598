import React, { Component } from 'react';
import styles from './ContactForm.module.scss';

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", subject: '', message: "" };
  }

  validateForm = () => {
    const {name, email} = this.state;
    if (!name || name.length === 0) {
      alert('נא להזין שם מלא');
      return false;
    }
    if (!email || email.length === 0) {
      alert('נא להזין אימייל');
      return false;
    }
    return true;
  }

  handleSubmit = e => {
    if (this.validateForm()) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contactForm", ...this.state })
      })
        .then(() => {
          alert("תודה, אצור איתך קשר בהקדם :)");
          this.setState({ name: "", email: "", subject: '', message: "" })
        })
        .catch(error => alert(error));
    }
    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { name, email, subject, message } = this.state;
    return (
      <form name="contactForm" onSubmit={this.handleSubmit} className={styles.contactForm} data-netlify="true">
        <div>
          <label>
            שם מלא: <input type="text" name="name" value={name} onChange={this.handleChange} />
          </label>
        </div>
        <div>
          <label>
            אימייל / טלפון: <input type="email" name="email" value={email} onChange={this.handleChange} />
          </label>
        </div>
        <div>
          <label>
            נושא: <input type="text" name="subject" value={subject} onChange={this.handleChange} />
          </label>
        </div>
        <div>
          <label>
            הודעה: <textarea name="message" value={message} onChange={this.handleChange} />
          </label>
        </div>
        <div className="text-center">
          <button type="submit">שליחה</button>
        </div>
      </form>
    );
  }
}
export default ContactForm;
