// @flow strict
import React from 'react';
import Author from './Author';
import Contacts from './Contacts';
import Copyright from './Copyright';
import Menu from './Menu';
import styles from './Sidebar.module.scss';
import { useSiteMetadata } from '../../hooks';
import ContactForm from '../ContactForm/ContactForm';

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const { author, copyright, menu } = useSiteMetadata();

  return (
    <div className={styles['sidebar']}>
      <div className={styles['sidebar__inner']}>
        <Author author={author} isIndex={isIndex} />
        <Menu menu={menu} />
        <div className={styles.content}>
          <h2>ציפורני צליל – בניית ציפורניים בפתח תקווה</h2>
          <p>ציפורני צליל הוא מכון בניית ציפורניים בפתח תקווה המנוהל על ידי בונת ציפורניים
            מומחית בפתח תקווה עם שנים של ניסיון.</p>
          <p>במכון מתבצעת עבודת ציפורניים ברמת האיכות הגבוהה ביותר – סביבת העבודה הינה מקצועית
            ונקיה עם שימוש בטכנולוגיה החדישה ביותר בשוק.</p>
          <p>רק מצפייה <strong>בגלריית תמונות</strong> הקישוטים של ציפורני צליל תוכלי לראות את
            רמת האיכות והיצירתיות של העיצובים השונים שניתן לעשות בסטודיו.</p>
          <p><u>מבין השירותים שניתן לקבל במכון :</u></p>
          <ul>
            <li>בניית ציפורניים בפתח תקווה</li>
            <li>מניקור פדיקור בפתח תקווה</li>
            <li>קישוטי ציפורניים מכל סוג</li>
          </ul>
          <p><strong>קצת על היסטורית המקום ועל מקימת ציפורני צליל</strong></p>
          <p>מירה שבו בעלת העסק, את לימודיה סיימה בסאן פרנסיסקו, שם עברה קורסים
            והשתלמויות. </p>
          <p>בשנת 1990 חזרה לארץ עם נסיון רב בתחום, וכיום היא מתחמה בטיפוח כף היד, בקישוטים
            מרהיבים ובשירות ברמה גבוה מאוד.</p>
          <p><u>כמו כן ניתן לקבל שירותים כגון:</u></p>
          <ul>
            <li>בניית ציפורנים באקריל</li>
            <li>קישוטים מרהיבים</li>
            <li>מניקור אמריקאי</li>
            <li>פיסולים באקריל צבעוני</li>
            <li>שעוות בכל חלקי הגוף</li>
            <li>עיצוב גבות</li>
            <li>מדי כמה חודשים מירה נוהגת להתעדכן בטרנדים החדשים, בצבעים החדשים ובאופנה
              העכשווית.
            </li>
            <li>במכון ניתן למצוא מגוון עצום של לקים מכל הסוגים.</li>
          </ul>
          <p>כל עבודות הציפורניים שלנו מרגישות כמעט כמו ציפורניים אמיתיות תוך כדי שמירה על
            חוזקה ועמידות.</p>
          <p>המראה של הציפורניים הוא יפה וטבעי ולא משנה באיזה סגנון תבחרו – אנחנו לא מתפשרים
            על איכות חומרי הגלם ועושים הכל בכדי שהשירות יתבצע על הצד הטוב ביותר.</p>
          <p><strong>צרי קשר כבר עכשיו למידע נוסף על בניית ציפורניים בפתח תקווה</strong></p>
          <p>לקביעת תור של בניית ציפורניים \ פדיקור מניקור ופגישת היכרות עם בונת ציפורניים
            מקצועית התקשרי כעת או השאירי הודעתך ונשוב אליך בהקדם.</p>
        </div>
        <Contacts contacts={author.contacts} />
        <h3>לקביעת תור</h3>
        <ContactForm />
        <Copyright copyright={copyright} />
      </div>
    </div>
  );
};

export default Sidebar;
